import "../util/mobile-table.css";
import Title from "antd/lib/typography/Title";
import { 
  Input,
  Table,
  Space,
  notification,
  Button,
  Collapse,
  Row,
  Col,
  Form,
  DatePicker,
  Dropdown,
  InputNumber,
  Popover,
  InputRef,
  Select,
} from "antd";
import  { ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ConfiguratorContext, } from "../context";
import { ArrayParam, NumberParam, StringParam, useQueryParam } from "use-query-params";
import { LogisticsReport, DateFilterType, PAGINATION_MAX_PAGE_SIZE, SortDirection, FgiStatus, FgiTransporter, AXIOS_CANCEL_MSG, TruckDateFilterType} from "../api/models";
import { FilterValue, SorterResult, TablePaginationConfig, SortOrder } from "antd/lib/table/interface";
import {debounce, isNaN} from "lodash";
import {AsyncState, useAsyncState} from "../hook/useAsyncState";
import {useIntl} from "react-intl";
import dayjs, { Dayjs } from "dayjs";
import axios, { CancelTokenSource } from "axios";
import { getCSVRow } from "../helpers/csv";
import useDateTypeAndRangeHook from "../hook/useDateTypeAndRangeHook";
import { AdvancedSearchConfig } from "../components/QuoteFilterControls";
import { useForm } from "antd/es/form/Form";
import _ from "lodash";
import Utils from "../util/util";
import { TruckLogisticsRequest } from "../api";
import { MoreOutlined} from "@ant-design/icons";
import FgiTransportersButtonModal from "../components/Quote/FgiTransportersButtonModal";
import TextArea from "antd/es/input/TextArea";
import { Link } from "react-router-dom";
import UploadFileBtn from "../components/UploadFileBtn";

const { RangePicker } = DatePicker;

type  LogisticsReportSort = SorterResult<LogisticsReport> | SorterResult<LogisticsReport>[]

interface LogisticsFilter {
  search?: string
  fgiStatus?: FgiStatus[]
  dateFilterType?: DateFilterType;
  dateFilterStart?: Dayjs;
  dateFilterEnd?: Dayjs;
}

const DEFAULT_FILTER = {
  dateFilterType: undefined,
  dateFilterStart: undefined,
  dateFilterEnd: undefined,
  dateFilterExcludeNull: true,
}

const DEFAULT_PAGE_SIZE = 50;
const LogisticsPage = () => {
  const intl = useIntl();

  const [logisticsReportLst, logisticsReportLstAsync] = useAsyncState<LogisticsReport[]>([]);
  const [fgiTransporterLst, fgiTransporterLstAsync] = useAsyncState<FgiTransporter[]>([]);
  const configurator = useContext(ConfiguratorContext);
  const [pageSizeQueryParam, setPageSizeQueryParam] = useQueryParam<number | undefined | null>("nr", NumberParam);
  const [currentPageParam, setCurrentPageParam] = useQueryParam<number | undefined | null>("p", NumberParam);
  const [searchFilterParam, setSearchFilterParam] = useQueryParam<string | undefined | null>("filter", StringParam);
  const [fgiStatusParam, setFgiStatusParam] = useQueryParam<Array<string | null> | undefined | null>("status", ArrayParam);

  const [dateFilterTypeParam, setDateFilterTypeParam] = useQueryParam<string|undefined|null>("dateFilterType", StringParam);
  const [dateFilterStartParam, setDateFilterStartParam] = useQueryParam<string|undefined|null>("dateFilterStart", StringParam);
  const [dateFilterEndParam, setDateFilterEndParam] = useQueryParam<string|undefined|null>("dateFilterEnd", StringParam);
  const logisticsReportLstCancelTokenSourceRef = useRef<CancelTokenSource>();
  const fgiTransporterLstCancelTokenSourceRef = useRef<CancelTokenSource>();

  const [filter, setFilter] = useState<LogisticsFilter>({
    ...DEFAULT_FILTER,
    search: searchFilterParam || undefined, //silly fix for null
    fgiStatus: fgiStatusParam as (FgiStatus[] | undefined), //silly fix for null
    //engineers: (engineersParam || undefined) as (string[] | undefined) || defaultEngineer, //silly fix for null
    dateFilterType: dateFilterTypeParam ? dateFilterTypeParam as DateFilterType : DEFAULT_FILTER.dateFilterType,
    dateFilterStart: dateFilterStartParam ? dayjs(dateFilterStartParam) : DEFAULT_FILTER.dateFilterStart,
    dateFilterEnd: dateFilterEndParam ? dayjs(dateFilterEndParam) : DEFAULT_FILTER.dateFilterEnd,
  });
  const [isExporting, setExporting] = useState<boolean>(false);

  const showAdvancedPanel = dateFilterStartParam?.length ||
    dateFilterEndParam?.length;

  const [filterForm] = useForm();

  const defaultSort = {
    columnKey: "readyToShip",
    order: 'ascend' as SortOrder
  };
  const [sort, setSort] = useState<LogisticsReportSort>( defaultSort);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: pageSizeQueryParam == null || pageSizeQueryParam > 500 ? DEFAULT_PAGE_SIZE : pageSizeQueryParam,
    current: currentPageParam == null || currentPageParam < 1 ? 1 : currentPageParam,
  });

  useEffect(() => {
    reloadFgiTransporters();
  }, []);


  useEffect(() => {
    setPageSizeQueryParam(pagination.pageSize);
    setCurrentPageParam(pagination.current);

    reloadLogisticsReport();
  }, [pagination.pageSize, pagination.current, filter, sort]);

  const reloadLogisticsReport = async () => loadLogisticsReport(logisticsReportLstAsync, pagination, filter, sort);
  
  const loadLogisticsReport = useCallback(debounce( async ( logisticsReportLstAsync:AsyncState<LogisticsReport[]>, pagination: TablePaginationConfig, filter:LogisticsFilter | undefined, sorter:LogisticsReportSort ) => {

    if ( logisticsReportLstCancelTokenSourceRef.current ) {
      logisticsReportLstCancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    logisticsReportLstCancelTokenSourceRef.current = cancelSource;

    const sort = [sorter].flat().map( sorter => ({
      field: sorter.columnKey?.toString() || defaultSort.columnKey,
      direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
    }));


    logisticsReportLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchLogisticsReport({
        ...filter,
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize || DEFAULT_PAGE_SIZE,
        sort,
      },
        cancelSource.token
      )

      logisticsReportLstCancelTokenSourceRef.current = undefined;

      logisticsReportLstAsync.setDone( resp.data.content );
      setPagination({ ...pagination, total: resp.data.totalElements });
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Report failed to load. " + errorMsg });
        logisticsReportLstAsync.setFail(e.message);
      }
    }

  }, 1000 ), [] );


  const reloadFgiTransporters = async () => loadFgiTransporters(fgiTransporterLstAsync);

  const loadFgiTransporters = useCallback(debounce( async ( fgiTransporterLstAsync:AsyncState<FgiTransporter[]>) => {

    if ( fgiTransporterLstCancelTokenSourceRef.current ) {
      fgiTransporterLstCancelTokenSourceRef.current.cancel( AXIOS_CANCEL_MSG );
    }
    const cancelSource = axios.CancelToken.source();
    fgiTransporterLstCancelTokenSourceRef.current = cancelSource;

    const sort = {
      field: "name",
      direction: 'asc' as SortDirection,
    }

    fgiTransporterLstAsync.setLoading();
    try {
      const resp = await configurator.api.fetchFgiTransporters({
          ...filter,
          page: 0,
          size: PAGINATION_MAX_PAGE_SIZE,
          sort,
        },
        cancelSource.token
      );

      fgiTransporterLstCancelTokenSourceRef.current = undefined;

      fgiTransporterLstAsync.setDone( resp.data.content );
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      if ( id !== AXIOS_CANCEL_MSG ) {
        const errorMsg = intl.formatMessage({ id });
        notification.error( { message: "Transporters failed to load. " + errorMsg });
        fgiTransporterLstAsync.setFail(e.message);
      }
    }

  }, 1000 ), [] );


  const handleChangeFgiNotes =  async (truckId:number, req:TruckLogisticsRequest) : Promise<LogisticsReport | undefined> => {
    return updateLogisticsReport( logisticsReportLstAsync, truckId, "notes", req );
  }
  const handleChangeFgiTransporter =  async (truckId:number, req:TruckLogisticsRequest) : Promise<LogisticsReport | undefined> => {
    return updateLogisticsReport( logisticsReportLstAsync, truckId, "transporterId", req );
  }
  const handleChangeFgiStatus =  async (truckId:number, req:TruckLogisticsRequest) : Promise<LogisticsReport | undefined> => {
    return updateLogisticsReport( logisticsReportLstAsync, truckId, "fgiStatus",  req );
  }
  const handleChangeShipDate =  async (truckId:number, req:TruckLogisticsRequest) : Promise<LogisticsReport | undefined> => {
    return updateLogisticsReport( logisticsReportLstAsync, truckId,  "shipDate", req );
  }
  const handleChangeTruckFgiTrueCost =  async (truckId:number, req:TruckLogisticsRequest) : Promise<LogisticsReport | undefined> => {
    return updateLogisticsReport( logisticsReportLstAsync, truckId,  "trueCost", req );
  }
  const NOT_FOUND = -1;
  const updateLogisticsReport =  async (logisticsReportAsync:AsyncState<LogisticsReport[]>, truckId:number, key:string, req:TruckLogisticsRequest) : Promise<LogisticsReport | undefined> => {

    try {
      logisticsReportAsync.setLoading();
      const resp = await configurator.api.updateTruckLogistics( truckId, key, req )

      const lst = logisticsReportLstAsync.val;

      const ndx = lst?.findIndex( r => r.truckId == resp.data.truckId ) ?? NOT_FOUND;
      if ( ndx === NOT_FOUND ) throw new Error("Truck not found.");

      lst?.splice( ndx, 1, resp.data );

      logisticsReportLstAsync.setDone( [...(lst || [])] );

      return resp.data;
    }
    catch(e:any) {
      const id = e.response?.data?.message || e.message ;
      const errorMsg = intl.formatMessage({ id });
      notification.error( { message: "Failed to update truck. " + errorMsg });

      logisticsReportAsync.setFail(errorMsg);
    }

    return;
  };

  const tableOnChange =  (pagination:TablePaginationConfig, _filters:Record<string, FilterValue | null>, sorter: LogisticsReportSort) => {
    setPagination(pagination);
    setSort(sorter);
  };

  const handleFilterChange = useCallback(debounce( async (_changedValues: Record<string, any>, allValues: any) => {

    const dateFilterType = allValues.dateFilterType;
    setDateFilterTypeParam(dateFilterType);

    const dateFilterStart = allValues.dateRange && allValues.dateRange?.[0];
    const dateFilterEnd = allValues.dateRange && allValues.dateRange?.[1]

    setSearchFilterParam(allValues.search);
    setFgiStatusParam(allValues.fgiStatus);

    setDateFilterTypeParam(dateFilterType);
    setDateFilterStartParam(dateFilterStart?.format("YYYY-MM-DD"));
    setDateFilterEndParam(dateFilterEnd?.format("YYYY-MM-DD"));

    setFilter({
      ...filter,
      ...allValues,
    dateFilterType,
    dateFilterStart,
    dateFilterEnd,
    dateFilterExcludeNull: true
    });
  }, 400), []);


  const getShippingCostDifference = ( r:LogisticsReport ) : number | undefined => (!( isNaN( r.oefCost ) && isNaN( r.trueCost ) )) ? ( ( r.oefCost || 0 ) - ( r.trueCost || 0 ) ) : undefined;

  const handleExport = async ( filter:LogisticsFilter | undefined, sorter:LogisticsReportSort ) => {
    try {

      const sort = [sorter].flat().map( sorter => ({
        field: sorter.columnKey?.toString() || defaultSort.columnKey,
        direction: ( sorter.order === 'ascend' ? 'asc' : 'desc') as SortDirection,
      }));

      setExporting(true);

      const resp = (await configurator.api.fetchLogisticsReport({
        ...filter,
        page: 0,
        size: PAGINATION_MAX_PAGE_SIZE,
        sort,
      })).data;

      const columns = [
        'TruckSn', 'Customer', 'Type', 'RTS', 'PartNo', 'Line', 'Status','ShipDate', 'StuckReason', 'Transporter', 'ShippingDestination', 'Notes', 'OEFCost', 'TrueCost', 'CostDifference'
      ];
      const headers = getCSVRow(columns);

      const data = resp.content.map((report:LogisticsReport) =>  {
        const diff = getShippingCostDifference(report);
        return getCSVRow([ 
          report.truckSn,
          report.endCustomer?.name || "",
          report.chassisModel,
          report.readyToShip ? dayjs(report.readyToShip).format("M/DD/YY") : "",
          report.partNo,
          report.productionLine,
          report.fgiStatus,
          report.shipDate ? dayjs(report.shipDate).format("M/DD/YY") : "",
          report.stuckReason || "",
          report.transporter?.name || "",
          report.shippingDestination?.name || "",
          report.notes || "",
          report.oefCost ? String( report.oefCost ) : "",
          report.trueCost? String( report.trueCost ) : "",
          diff ? String(diff) : ""
        ])
      });

      const csv = [ headers, ...data];
      var blob = new Blob([csv.join('\n')], { type: 'text/csv;charset=utf-8' });
      var url = URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'logistics_report-export-' + (new Date()) + '.csv';
      document.body.appendChild(a);
      a.click();
    }
    catch (e:any) {
      const errorMsg = intl.formatMessage({ id: e.response?.data.message || e.message });
      notification.error( { message: "Export failed. " + errorMsg });
    }

    setExporting(false);

  };

  const getDateRange = (filter: LogisticsFilter | undefined) => {
    if (filter?.dateFilterStart && filter?.dateFilterEnd) {
      return [dayjs(filter.dateFilterStart), dayjs(filter.dateFilterEnd)];
    }
    return undefined;
  }
  const {validateDateFilterType, validateRangePicker, filterChange} = useDateTypeAndRangeHook(filterForm, "dateFilterType", "dateRange", handleFilterChange );

  const optionMenuItems = [
    {
      key: "transporters",
      label: <FgiTransportersButtonModal type="text" onChange={reloadFgiTransporters} />
    },
  ];

  if ( configurator.isAdmin() ) {
    optionMenuItems.push( {
      key: "uploadMonday",
      label: <UploadFileBtn type="text" url={configurator.api.getUploadMondayDataUrl()}>Upload Monday XLS</UploadFileBtn>
    });

  }


  return <div className="site-layout-background">

    <Space direction="vertical" size="small" style={{ display: 'flex' }}>
      <Title level={2}>Logistics Dashboard</Title>

      <Form
        initialValues={{
          ...filter, 
          dateRange: getDateRange(filter)
        }}
        form={filterForm} 
        onValuesChange={filterChange}
        layout="vertical"
        className="custom-form-item"
      >
        <Row gutter={[10, 10]}>
          <Col flex={"40rem"} >
            <Form.Item name="search" >
              <Input placeholder="Search Trucks" allowClear />
            </Form.Item>
          </Col>
          <Col flex={"20rem"}>
            <Form.Item name="fgiStatus" >
              <Select
                mode="multiple"
                placeholder="Filter status"
                options={Object.values(FgiStatus).map(e => ({ label: Utils.snakeCaseToFirstLetterCapitalized(e), value:e }))}
                allowClear
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
          <Col flex={"auto"} >
            <div style={{display: "flex", flexDirection: "row-reverse", gap:"10px"}}>

              <Dropdown trigger={["click"]}
                menu={{items: optionMenuItems}}
              >
                {/* this div is to avoid a warning with strict mode */}
                <div>
                  <Button 
                    type="primary" 
                    icon={<MoreOutlined/>} 
                  >Options</Button>
                </div>
              </Dropdown>

              <Button type="primary" loading={isExporting} onClick={() => handleExport(filter, sort)}>Export</Button>
            </div>
          </Col>
        </Row>

        <Collapse
          style={{ width: '100%'}} size="small"
          defaultActiveKey={showAdvancedPanel ? "advanced-search" : undefined }
          {...AdvancedSearchConfig}
          items={[{
            key: "advanced-search",
            label: <span style={{color: "#1677FF"}}>Advanced Search</span>,
            forceRender: true,
            children:
            <Row gutter={24}>
              <Col xs={24} xxl={3}>
                <Form.Item
                  name="dateFilterType"
                  label="Date Filter"
                  rules={[{ validator: validateDateFilterType }]}
                >
                  <Select
                    options={Object.values(TruckDateFilterType).map(e => ({ label: Utils.snakeCaseToFirstLetterCapitalized(e), value:e }))}
                    allowClear
                    style={{width: "100%"}}
                  />
                </Form.Item>

              </Col>
              <Col xs={24} xxl={10}>
                <Form.Item
                  name="dateRange"
                  label="Date Filter Range"
                  rules={[{ validator: validateRangePicker }]}
                >
                  <RangePicker
                    name="range"
                    style={{width: "100%"}}
                  />
                </Form.Item>
              </Col>

            </Row>
          }]}
        />
      </Form>

      <Table
        rowKey="truckId"
        scroll={{ x: true }}
        style={{ width: "100%" }}
        bordered
        loading={logisticsReportLstAsync.isLoading() }
        onChange={tableOnChange}
        pagination={pagination}
        dataSource={logisticsReportLst}
        columns={ [
          {
            title:"Truck Sn",
            dataIndex: "truckSn",
            sorter:true,
          },
          {
            title:"Customer",
            //key: "quoteRevision.endCustomer.name",
            //sorter:true,
            dataIndex: "endCustomer",
            render: (c) => c?.name,
          },
          {
            title:"Type",
            //key: "quoteRevision.model.motorFamily",
            //sorter:true,
            dataIndex: "chassisModel",
          },
          {
            title:"Ready To Ship (RTS)",
            key: "readyToShip",
            sorter:true,
            dataIndex: "readyToShip",
            render: (sd) => sd ? dayjs(sd).format("M/DD/YY") : "",
          },
          {
            title:"Part No",
            render: (r) => <Link to={"/configurator/" + encodeURI(r.quoteId)} target="_blank">{r.partNo}</Link>
          },
          {
            title:"Line",
            //sorter:true,
            //key: "quoteRevision.partNumberSuffix",
            dataIndex: "productionLine",
          },
          {
            title:"Status",
            key: "fgiStatus",
            sorter:true,
            render: (r) => <FGIStatusToggle report={r} onChange={handleChangeFgiStatus} />
          },
          {
            title:"Ship Date",
            render: (r) => <SelectShipDate report={r} onChange={handleChangeShipDate} />,
            key: "shipDate",
            sorter:true,
          },
          {
            title:"Transporter",
            key: "fgiTransporter.name",
            sorter:true,
            render: (r) => <FgiTransporterToggle fgiTransporterLst={fgiTransporterLst} report={r} onChange={handleChangeFgiTransporter} />
          },
          {
            title:"Shipping Destination",
            dataIndex: "shippingDestination",
            render: (sd) => sd?.name,
            //key: "quoteRevision.shippingDestination.name",
            //sorter:true,
          },
          {
            title:"Notes",
            key: "fgiNotes",
            sorter: true,
            render: (r) => <FGINotesInput report={r} onChange={handleChangeFgiNotes} />,
          },
          {
            title:"OEF Cost",
            dataIndex: "oefCost",
            render: (c) => Utils.formatMoney(c, ""),
          },
          {
            title:"True Cost",
            render: (r) => <FGITrueCostInput report={r} onChange={handleChangeTruckFgiTrueCost} />,
            key: "fgiTrueCost",
            sorter: true,
          },
          {
            title:"Cost Difference",
            render: r => Utils.formatMoney( getShippingCostDifference(r), "" )
          },

        ]}
      />
    </Space>
  </div>
};

const FGIStatusToggle = (props:{
  report:LogisticsReport | undefined
  onChange?: (id:number, req:TruckLogisticsRequest) => void
}) => {

  const btnStyle = {borderBottom: "1px solid black"};
  const [selectedStatus, setSelectedStatus] = useState<FgiStatus>();
  const shippingDateInputRef = useRef<InputRef>(null);
  const stuckReasonInputRef = useRef<InputRef>(null);

  const handleSelectStatus = (s:any) => {
    if ( !props.report?.truckId ) return;

    if ( FgiStatus.Stuck === s.key ) {
      setSelectedStatus(s.key);
    }
    else if ( FgiStatus.Shipped === s.key ) {

      //select a ship date if none exists
      if (!props.report.shipDate) {
        setSelectedStatus(s.key);
      }
      else {
        //else use existing ship date
        props.onChange?.( props.report.truckId, {  
          fgiStatus: s.key,
          shipDate: props.report.shipDate
        } );
      }
    }
    else {
      props.onChange?.(props.report.truckId, {
        fgiStatus: s.key 
      });
    }
  }

  const focusSelected = () => {
    if ( selectedStatus === FgiStatus.Shipped ) {
      shippingDateInputRef.current?.focus();
    }
    if ( selectedStatus === FgiStatus.Stuck ) {
      stuckReasonInputRef.current?.focus();
    }
  }

  const handleChangeShipDate = (value: Dayjs | null, _dateString: string | string[]) => {
    if ( !props.report?.truckId ) return;

    const fgiStatus = selectedStatus;
    setSelectedStatus(undefined);


    const shipDate = value?.toDate();
    if (!shipDate ) {
      notification.error( { message: "A date is required for Shipped." });
      return;
    }
  
    props.onChange?.( props.report.truckId, {  
      fgiStatus,
      shipDate,
    } );
  }
  const handleBlur = () => {
    if ( !props.report?.truckId ) return;

    const fgiStatus = selectedStatus;
    setSelectedStatus(undefined);

    const stuckReason =  stuckReasonInputRef.current?.input?.value;
    if (!stuckReason?.length ) {
      notification.error( { message: "A reason is required for Stuck." });
      return;
    }

    props.onChange?.( props.report.truckId, {  
      fgiStatus,
      stuckReason,
    } );

  }

  const items = Object.values(FgiStatus).map( s => ({label: _.startCase(s), key: s}));
  return <Popover
    open={!!selectedStatus}
    afterOpenChange={(open) => {
      if( open ) focusSelected();
    }}
    content={ <>
      {( selectedStatus === FgiStatus.Stuck ) && <Input placeholder="Provide Stuck Reason" ref={stuckReasonInputRef} allowClear 
        onBlur={handleBlur} onPressEnter={() => stuckReasonInputRef.current?.blur()} />}
      { //@ts-ignore
        ( selectedStatus === FgiStatus.Shipped ) && <DatePicker placeholder="Shipping Date" ref={shippingDateInputRef} allowClear 
          onBlur={() => setSelectedStatus(undefined)}  
          value={props.report?.shipDate ? dayjs(props.report.shipDate) : undefined}
          onChange={handleChangeShipDate} />}
    </> }
  >
    <Dropdown trigger={["click"]}
      menu={{
        items,
        selectable: true,
        selectedKeys:props.report?.fgiStatus ? [props.report?.fgiStatus] : [],
        multiple: false,
        onSelect:handleSelectStatus ,
        onDeselect:handleSelectStatus,
      }}
    >
      <Button className="ghostBmButton" type="text">
        <div style={{textAlign: "left"}}>
        <span style={btnStyle}>{props.report?.fgiStatus}</span>
        {props.report?.fgiStatus === FgiStatus.Stuck && <div><span style={{fontStyle:"italic"}}>{props.report.stuckReason}</span></div>}
        </div>
      </Button>
  </Dropdown>
  </Popover>
}


const FgiTransporterToggle = (props:{
  fgiTransporterLst:FgiTransporter[] | undefined,
  report:LogisticsReport | undefined,
  onChange?: (id:number, t:TruckLogisticsRequest) => void
}) => {

  const handleSelectTransport = (s:any) => {
    if ( !props.report?.truckId ) return;

    const transporterId = props.fgiTransporterLst?.find( t => t.key === s.key )?.id;

    props.onChange?.( props.report?.truckId, {
      transporterId
    });
  }

  const btnStyle = {borderBottom: "1px solid black"};

  const items = props.fgiTransporterLst?.map( t => ({label: <>{t.name}</> as ReactElement, key: t.key as React.Key})) || [];

  return <Dropdown trigger={["click"]}
        menu={{
          items:items,
          selectable: true,
          selectedKeys: props.report?.transporter?.key ? [props.report?.transporter?.key] : [],
          onSelect: handleSelectTransport
        }}
      >
        <Button className="ghostBmButton" type="text"><span style={btnStyle}>{props.report?.transporter?.name || "None"}</span></Button>
      </Dropdown>
}

const FGITrueCostInput = (props:{
  report:LogisticsReport | undefined
  onChange?: (id:number, s:TruckLogisticsRequest) => void
}) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const btnStyle = {borderBottom: "1px solid black"};
  const [trueCost, setTrueCost] = useState<string>();

  const handleOpen = () => {
    setTrueCost(props.report?.trueCost?.toString());
    setIsOpen(true);
  }
  const handleBlur = () => {

    setIsOpen(false);

    if ( !props.report?.truckId ) return;

    props.onChange?.( props.report.truckId, { 
      trueCost: trueCost ? Number(trueCost) : undefined
    } );
  }

  return <Popover
    trigger={"click"}
    afterOpenChange={(open) => {
      if( open ) {
        inputRef.current?.focus();
      }
    }}
    open={isOpen}
    content={<InputNumber
      ref={inputRef}
      value={trueCost}
      onChange={(value) => setTrueCost( value?.toString() )}
      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      parser={value => value!.replace(/\$\s?|(,*)/g, '')}
      placeholder="Type dollar amount."
      controls={false}
      onBlur={handleBlur}
      onPressEnter={() => inputRef.current?.blur()}
    /> }
    arrow={false}
  >
    <Button className="ghostBmButton" type="text" onClick={handleOpen}><span style={btnStyle}>{Utils.formatMoney(props.report?.trueCost, "")}</span></Button>
  </Popover>;
}

const FGINotesInput = (props:{
  report:LogisticsReport | undefined
  onChange?: (id:number, s:TruckLogisticsRequest) => void
}) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputRef = useRef<any>(null);
  const btnStyle = {borderBottom: "1px solid black"};
  const [notes, setNotes] = useState<string>();

  const handleOpen = () => {
    setNotes( props.report?.notes );
    setIsOpen(true);
  }
  const handleBlur = () => {

    setIsOpen(false);

    if ( !props.report?.truckId ) return;

    props.onChange?.( props.report.truckId, { notes } );
  }

  return <Popover
    trigger={"click"}
    afterOpenChange={(open) => {
      if( open ) {
        inputRef.current?.focus();
      }
    }}
    open={isOpen}
    content={<TextArea
      ref={inputRef}
      value={notes}  
      placeholder="Type notes."
      onChange={(e) => setNotes(e.target.value)}
      onBlur={handleBlur}
      onPressEnter={() => inputRef.current?.blur()}
    /> }
    arrow={false}
  >
    <Button className="ghostBmButton" type="text" onClick={handleOpen} title={props.report?.notes}>
                    <span style={{...btnStyle, maxWidth:"5rem", overflow: "hidden", textOverflow: "ellipsis"}}>{props.report?.notes}</span>
    </Button>
  </Popover>;
}

const SelectShipDate = (props:{
  report:LogisticsReport | undefined
  onChange?: (id:number, req:TruckLogisticsRequest) => void
}) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const btnStyle = {borderBottom: "1px solid black"};
  const [shipDate, setShipDate] = useState<Date>();
  const inputRef = useRef<InputRef>(null);

  const handleOpen = () => {
    setShipDate( props.report?.shipDate );
    setIsOpen(true);
  }
  const handleBlur = () => {
      setIsOpen(false);
  }

  const handleChangeShipDate = (value: Dayjs | null, _dateString: string | string[]) => {
    const shipDate = value?.toDate();
    setShipDate( shipDate );

    if ( !props.report?.truckId ) return;

    props.onChange?.( props.report.truckId, { shipDate } );
  }

  return <Popover
    open={isOpen}
    afterOpenChange={(open) => {
      if( open ) {
        inputRef.current?.focus();
      }
    }}
    content={<>
      { //@ts-ignore
        <DatePicker placeholder="Shipping Date" ref={inputRef} allowClear 
          value={shipDate ? dayjs(shipDate) : undefined}
          onChange={handleChangeShipDate} 
          onBlur={handleBlur}
        />}
    </>}
  >
      <Button className="ghostBmButton" type="text" onClick={handleOpen} >
        <span style={btnStyle}>{props.report?.shipDate ? dayjs(props.report.shipDate).format("M/DD/YY") : ""}</span>
      </Button>
  </Popover>
}

export default LogisticsPage;
